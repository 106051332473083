<template>
  <div>
    <el-form
      ref="nodeForm"
      :rules="rules"
      label-position="top"
      label-width="100px"
      :model="nodeToBind"
      :hide-required-asterisk="true"
    >
      <el-tabs v-model="activeTab" class="tabs" style="font-size: 1.2em;">
        <el-tab-pane :label="__('Variables')" name="variables">
          <el-scrollbar :native="false">
            <div class="tabPane">
              <el-form-item
                prop="start_node.data.variable_rules.data"
                :label="__('Assign values to variables')"
              >
                <extract-words
                  v-model="variableRules"
                  :variables="
                    variablesCollectionOfTypes([
                      'single_value',
                      'secure',
                      'system',
                      'xsip'
                    ])
                  "
                  rule_title="Config Value"
                  :use-expression-builder="false"
                  add-description
                  add-configurable
                  :allow-system-variables="true"
                  :allowXSIPVariables="true"
                />
              </el-form-item>
            </div>
          </el-scrollbar>
        </el-tab-pane>
        <el-tab-pane
          :label="__('Advanced')"
          name="advanced"
          v-if="isTaskTypeChatbot"
        >
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item
                prop="incoming_json"
                :label="__('Incoming JSON Format')"
              >
                <el-input
                  type="textarea"
                  :rows="8"
                  :placeholder="__('Define sample Json to receive user data')"
                  v-model="incomingJson"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </el-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BaseNode from "./BaseNode";
import _ from "lodash";
import ExtractWords from "./components/ExtractWords";
import { filterRowsIfEveryKeyValueIsAbsent } from "@/utils/collection";
import { NODE_TYPES } from "@/constants/nodes";
import { variableRulesValidation } from "@/utils/formValidationRules";

export default {
  mixins: [BaseNode],
  components: {
    ExtractWords
  },
  data() {
    const validateVariableRules = (rule, value, callback) => {
      let isValid = variableRulesValidation(rule, value, this);
      isValid
        ? callback()
        : callback(__("variable rules configuration incomplete"));
    };
    const validateMessageJson = (rule, value, cb) => {
      value = this.incomingJson;
      if (!value) {
        cb();
      } else if (this.hasInvalidVariable(value)) {
        cb(
          new Error(
            // eslint-disable-next-line
            __("Variable input not allowed or Invalid variables found in Json content")
          )
        );
      } else {
        let valid = this.validJsonValue(value);
        if (valid) {
          cb();
        } else {
          cb(new Error(__("JSON format is invalid")));
        }
      }
    };
    return {
      jsonArrayVariable: {},
      activeTab: "variables",
      rules: {
        "start_node.data.variable_rules.data": {
          validator: validateVariableRules
        },
        incoming_json: [
          {
            validator: validateMessageJson,
            trigger: "blur"
          }
        ]
      }
    };
  },
  mounted() {
    if (this.isTaskTypeChatbot) {
      this.setIncomingJsonArrayVar();
    }
  },
  computed: {
    ...mapGetters("variables", {
      variablesCollectionOfTypes: "variablesCollectionOfTypes"
    }),
    variableRules: {
      get: function() {
        let { data: variable_rules } = _.cloneDeep(
          this.nodeToBind.start_node.data.variable_rules
        );
        variable_rules = _.filter(variable_rules, function(item) {
          return item.rule_value !== "root";
        });
        return _.isEmpty(variable_rules)
          ? []
          : this.maskConfigValues(variable_rules);
      },

      set: function(val) {
        this.newVariableCreated = _.some(
          val,
          variable => variable.variable_id === -1
        );
        if (
          // update only if there is a change in word rules
          !_.isEqual(this.variableRules, val) ||
          _.isEmpty(this.variableRules)
        ) {
          this.$set(
            this.nodeToBind.start_node.data.variable_rules,
            "data",
            this.maskConfigValues(val)
          );
        }
      }
    },
    incomingJson: {
      get: function() {
        return !_.isEmpty(this.jsonArrayVariable)
          ? this.jsonArrayVariable.default_value
          : "";
      },
      set: function(val) {
        this.jsonArrayVariable.default_value = val;
        this.toggleNodeUpdateStatus(true);
      }
    },
    isTaskTypeChatbot() {
      return this.task_type === "chatbot";
    }
  },
  methods: {
    ...mapActions("canvas", {
      toggleNodeUpdateStatus: "toggleNodeUpdateStatus"
    }),
    maskConfigValues(variableRules) {
      if (this.isTaskReadOnly) {
        return _.map(variableRules, rule => {
          if (rule.import_configurable) {
            rule.rule_value = rule.rule_value.replace(/[^-.]/g, "x");
          }
          return rule;
        });
      } else {
        return variableRules;
      }
    },
    validJsonValue(value) {
      let validJson = false;
      try {
        JSON.parse(value);
        validJson = true;
      } catch (e) {
        validJson = false;
      }
      return validJson;
    },
    setIncomingJsonArrayVar() {
      const variableRules = this.clickedNode.start_node.data.variable_rules
        .data;
      this.jsonArrayVariable = _.find(variableRules, function(item) {
        return item.rule_value === "root";
      });
      let parsedValue = "";
      if (
        !_.isEmpty(this.jsonArrayVariable) &&
        this.jsonArrayVariable.default_value !== ""
      ) {
        parsedValue = JSON.parse(this.jsonArrayVariable.default_value);
        parsedValue = _.isEmpty(parsedValue.ws_response_data)
          ? ""
          : JSON.stringify(parsedValue.ws_response_data, null, 2);
      }
      this.incomingJson = parsedValue;
    },
    cleanUpNodeToPrepareForSubmit() {
      const nodeToCleanUp = _.cloneDeep(this.nodeToBind);
      const variableRules = filterRowsIfEveryKeyValueIsAbsent(
        _.map(this.variableRules, variableRule => {
          const {
            default_value,
            rule_value,
            variable_id,
            variable_name,
            description,
            import_configurable
          } = variableRule;
          return {
            default_value,
            rule_value: rule_value.toString().trim(),
            variable_id,
            variable_name: variable_name.toString().trim(),
            description: (description && description.toString().trim()) || "",
            import_configurable
          };
        }),
        "rule_value,variable_name,description,import_configurable"
      );
      if (this.isTaskTypeChatbot) {
        let incomingJsonArrayVar = _.cloneDeep(this.jsonArrayVariable);
        const jsonArrayValue = incomingJsonArrayVar.default_value;
        let isValid = this.validJsonValue(jsonArrayValue);
        if (isValid || jsonArrayValue === "") {
          incomingJsonArrayVar.default_value =
            jsonArrayValue === ""
              ? ""
              : JSON.stringify({
                  ws_response_data: JSON.parse(jsonArrayValue)
                });
          variableRules.push(incomingJsonArrayVar);
        }
      }
      this.$set(
        nodeToCleanUp.start_node.data.variable_rules,
        "data",
        variableRules
      );
      return nodeToCleanUp;
    },
    cleanUpNode() {
      this.nodeToBind = this.cleanUpNodeToPrepareForSubmit();
      this.createOrEditNode();
    }
  },
  created() {
    if (!this.nodeToBind.node_id || _.isEmpty(this.nodeToBind.start_node)) {
      this.$set(this.nodeToBind, "start_node", {});
      this.$set(this.nodeToBind.start_node, "data", {});

      if (_.isEmpty(this.nodeToBind.start_node.data.variable_rules)) {
        this.$set(this.nodeToBind.start_node.data, "variable_rules", {});
      }

      this.$set(this.nodeToBind, "node_type", NODE_TYPES.START.NODE_TYPE);
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/node_common.scss";
.tabPane {
  max-height: 70vh;
  padding-right: 30px;
}
</style>
